<script>
import appConfig from "@/app.config";
import { timeSummary } from "../../../../path";
import Multiselect from "vue-multiselect";

export default {
  name: "time-summary",
  props: ["filters"],
  components: {
    Multiselect,
  },
  data() {
    return {
      key: 0,
      key1: 0,
      hourlychat: {
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: true,
            },
          },
          colors: ["#556ee6", "#34c38f", "#ffc115", "#e71212"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [3, 3],
            curve: "smooth",
          },
          title: {
            text: "Hourly Calls",
            align: "left",
          },
          grid: {
            row: {
              colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.2,
            },
            borderColor: "#f1f1f1",
          },
          markers: {
            style: "inverted",
            size: 6,
          },
          xaxis: {
            categories: Object.keys(appConfig.hourly_calls),
            title: {
              text: "Hours",
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            offsetY: -25,
            offsetX: -5,
          },
          responsive: [
            {
              breakpoint: 600,
              options: {
                chart: {
                  toolbar: {
                    show: false,
                  },
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
        },
        series: [
          {
            name: "Total Calls",
            data: [],
          },
          {
            name: "Total Call Duration (minutes)",
            data: [],
          },
          {
            name: "Call Status",
            data: [],
          },
          {
            name: "Call Status Duration (minutes)",
            data: [],
          },
        ],
      },
      mtddatachart: {
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: true,
            },
          },
          colors: ["#556ee6", "#34c38f", "#ffc115", "#e71212"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [3, 3],
            curve: "smooth",
          },
          title: {
            text: "Monthly Calls",
            align: "left",
          },
          grid: {
            row: {
              colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.2,
            },
            borderColor: "#f1f1f1",
          },
          markers: {
            style: "inverted",
            size: 6,
          },
          xaxis: {
            categories: [],
          },
          legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            offsetY: -25,
            offsetX: -5,
          },
          responsive: [
            {
              breakpoint: 600,
              options: {
                chart: {
                  toolbar: {
                    show: false,
                  },
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
        },
        series: [
          {
            name: "Total Calls",
            data: [],
          },
          {
            name: "Total Call Duration (minutes)",
            data: [],
          },
          {
            name: "Call Status",
            data: [],
          },
          {
            name: "Call Status Duration (minutes)",
            data: [],
          },
        ],
      },
      statuses: [],
      hourly_call_statuses: null,
      monthly_call_statuses: null,
    };
  },
  mounted() {
    this.getCallStatuses();
    this.getHourlyCalls();
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getHourlyCalls();
        if (this.hourly_call_statuses != null) {
          this.getStatusLevelCalls();
        } else if (this.monthly_call_statuses != null) {
          this.getStatusLevelCalls(true);
        }
      },
    },
    hourly_call_statuses() {
      this.getStatusLevelCalls();
    },
    monthly_call_statuses() {
      this.getStatusLevelCalls(true);
    },
  },
  methods: {
    async getCallStatuses() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/telecallers/callStatuses"
        );
        this.statuses = response.data.call_statuses;
      } catch (err) {
        console.log(err);
      }
    },
    async getHourlyCalls() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + timeSummary + '?is_field_mr=false',
          {
            params: this.filters,
          }
        );
        this.hourlychat.series[0].data = [];
        this.hourlychat.series[1].data = [];
        Object.values(appConfig.hourly_calls).forEach((hr) => {
          let index = response.data.hourly_calls.findIndex(
            (f) => f.hour === hr
          );
          let total_calls = 0;
          let total_duration = 0;
          if (index !== -1) {
            total_calls = response.data.hourly_calls[index].total_calls;
            total_duration = parseInt(
              response.data.hourly_calls[index].total_duration
            );
          }
          this.hourlychat.series[0].data.push(total_calls);
          this.hourlychat.series[1].data.push((total_duration / 60).toFixed(2));
        });

        this.mtddatachart.chartOptions.xaxis.categories =
          response.data.mtd_calls.map((d) => d.date);
        this.mtddatachart.series[0].data = response.data.mtd_calls.map(
          (d) => d.total_calls
        );
        this.mtddatachart.series[1].data = response.data.mtd_calls.map((d) =>
          (d.total_duration / 60).toFixed(2)
        );
        this.key += 1;
        this.key1 += 1;
      } catch (err) {
        console.log(err);
      }
    },
    async getStatusLevelCalls(monthly = false) {
      let params = Object.keys(this.filters).length > 0 ? this.filters : {};
      if (monthly === true) {
        params.call_statuses = this.monthly_call_statuses.map((s) => s.title);
      } else {
        params.call_statuses = this.hourly_call_statuses.map((s) => s.title);
      }
      if (params.call_statuses.length === 0) {
        if (monthly === true) {
          this.mtddatachart.series[2].data = [];
          this.mtddatachart.series[3].data = [];
          this.key1 += 1;
        } else {
          this.hourlychat.series[2].data = [];
          this.hourlychat.series[3].data = [];
          this.key += 1;
        }
        return;
      }
      const response = await this.$http.get(
        appConfig.api_base_url + timeSummary + '?is_field_mr=false',
        {
          params: params,
        }
      );
      if (monthly === true) {
        this.mtddatachart.chartOptions.xaxis.categories =
          response.data.mtd_calls.map((d) => d.date);
        this.mtddatachart.series[2].data = response.data.mtd_calls.map(
          (d) => d.total_calls
        );
        this.mtddatachart.series[3].data = response.data.mtd_calls.map((d) =>
          (d.total_duration / 60).toFixed(2)
        );
        this.key1 += 1;
      } else {
        this.hourlychat.series[2].data = [];
        this.hourlychat.series[3].data = [];
        Object.values(appConfig.hourly_calls).forEach((hr) => {
          let index = response.data.hourly_calls.findIndex(
            (f) => f.hour === hr
          );
          let total_calls = 0;
          let total_duration = 0;
          if (index !== -1) {
            total_calls = response.data.hourly_calls[index].total_calls;
            total_duration = parseInt(
              response.data.hourly_calls[index].total_duration
            );
          }
          this.hourlychat.series[2].data.push(total_calls);
          this.hourlychat.series[3].data.push((total_duration / 60).toFixed(2));
        });
        this.key += 1;
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12" :key="key">
      <div class="card">
        <div class="card-header bg-white">
          <div class="form-group col-md-4 float-md-right">
            <label>Product Call Status</label>
            <multiselect
              v-model="hourly_call_statuses"
              :close-on-select="false"
              :options="statuses"
              track-by="title"
              label="display_key"
              :multiple="true"
            ></multiselect>
          </div>
        </div>
        <div class="card-body">
          <h4 class="card-title mb-4">Hourly Summary</h4>
          <!-- Spline Area chart -->
          <apexchart
            class="apex-charts"
            height="350"
            type="area"
            dir="ltr"
            :series="hourlychat.series"
            :options="hourlychat.chartOptions"
          ></apexchart>
        </div>
      </div>
    </div>
    <div class="col-lg-12" :key="key1">
      <div class="card">
        <div class="card-header bg-white">
          <div class="form-group col-md-4 float-md-right">
            <label>Product Call Status</label>
            <multiselect
              v-model="monthly_call_statuses"
              :close-on-select="false"
              :options="statuses"
              track-by="title"
              label="display_key"
              :multiple="true"
            ></multiselect>
          </div>
        </div>
        <div class="card-body">
          <h4 class="card-title mb-4">MTD Summary</h4>
          <!-- Spline Area chart -->
          <apexchart
            class="apex-charts"
            height="350"
            type="area"
            dir="ltr"
            :series="mtddatachart.series"
            :options="mtddatachart.chartOptions"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.apexcharts-toolbar {
  top: -38px !important;
}
</style>
