<script>
import appConfig from "@/app.config";

import moment from "moment";


export default {
  props : ['telecallers_data'],
  components: {  },
  watch : {
    telecallers_data : {
      deep : true,
      handler(v) {
        this.telecallers = v;
        this.totalRows = this.telecallers.length;
        this.getIncallStatus();
      }
    }
  },
  data() {
    return {
      telecallers : [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [5,10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "DigiMR",
      sortDesc: false,
      key : 0,
      fields: [
        { key: "username", sortable: true ,label : 'Digi MR'},
        { key: "status", sortable: true , label : 'In Call'},
        { key: "check_in", sortable: true , label : 'CheckIn'},
        { key: "check_out", sortable: true , label : 'CheckOut'},
        { key: "idle_time", sortable: true , label : 'Last Activity'},
        {key : 'product_call_status', label: "Last Call Status", sortable: true, color : 'grey' },
        {key : 'title', label: "Last Project Discussed", sortable: true },
        {key : 'last_call', label: "Last Call Made", sortable: true },
        {key : 'doctor_name', label: "Doctor", sortable: true },
        {key : 'speciality', label: "Speciality", sortable: true },
        {key : 'state', sortable: true },
        {key : 'city', sortable: true },
        {key : 'call_time_in_sec',label : 'Call Duration', sortable: true },
      ]
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.telecallers.length;
    }
  },
  mounted() {
    // Set the initial number of items
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getIncallStatus(){
        try{
          const response = await this.$http.get(appConfig.api_base_url + '/telecallers/inCallStatus?is_field_mr=false');
          response.data.in_call_users.forEach(res => {
            let index = this.telecallers.findIndex(t => t.id === res.id);
            if(typeof this.telecallers[index] != 'undefined'){
            this.telecallers[index].status = res.in_call;
            }
          });
          response.data.histories.forEach(res1 => {
            let index = this.telecallers.findIndex(t => t.id === res1.telecaller_id);
            let arr = ['product_call_status','title','last_call','doctor_name','speciality'
              ,'state','city','call_time_in_sec'];
            if(typeof this.telecallers[index] != 'undefined') {
            this.telecallers[index].idle_time = moment(res1.last_call).fromNow();
            arr.forEach(a => {
              this.telecallers[index][a] = a === 'call_time_in_sec' ? res1[a] + ' sec' : res1[a];
            });
            }
          });
          response.data.attendances.forEach(at => {
            let index = this.telecallers.findIndex(t => t.id === at.telecaller_id);
            if(at.status == 'check_in' && typeof this.telecallers[index] != 'undefined'){
              this.telecallers[index].check_in = at.time;
              this.telecallers[index].checkin_address = at.address;
            }else if(at.status == 'check_out' && typeof this.telecallers[index] != 'undefined'){
              this.telecallers[index].check_out = at.time;
              this.telecallers[index].checkout_address = at.address;
            }
          });
          this.key ++;
        }catch(err) {
          console.log(err);
        }
    },
    getFieldNames(){
      // console.log(d);
      // return d;
      // console.log('hello');
    }
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Latest Call Activity</h4>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive mb-0">
            <b-table
                :items="telecallers"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :key="key"
            >
              <template v-slot:cell(status)="row">
                <i class="fas fa-check-circle" style="color : #1cbb8c" v-if="row.value !== ''"></i>
                <i class="fas fa-times-circle" style="color : #ff3d60" v-else></i>
              </template>
              <template v-slot:cell(check_in)="row">
                {{row.value}}
                {{row.item.checkin_address}}
              </template>
              <template v-slot:cell(check_out)="row">
                {{row.value}}
                {{row.item.checkout_address}}
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>