<script>
import appConfig from "@/app.config";
import utils from "../../../../helpers/utils";
import { digimrProjectSumary } from "../../../../path";

/**
 * Advanced table component
 */
export default {
  data() {
    return {
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "DigiMR",
      sortDesc: false,
      fields: [
        { key: "project", sortable: true, label: "Project" },
        {
          key: "total_digimrs_updated",
          sortable: true,
          label: "Total Digi MRs",
        },
        { key: "total_calls_updated", sortable: true, label: "Total Calls" },
        {
          key: "total_doctors_contacted_updated",
          label: "Total Doctors Contacted",
          sortable: true,
          color: "grey",
        },
        {
          key: "total_successful_calls_updated",
          label: "Total Successful Calls",
          sortable: true,
        },
        {
          key: "total_doctors_contacted_successful_updated",
          label: "Total Doctors Contacted Successful",
          sortable: true,
        },
        {
          key: "avg_call_duration_updated",
          label: "Avg Call Duration",
          sortable: true,
        },
        {
          key: "avg_successful_call_duration_updated",
          label: "Avg Successful Call Duration",
          sortable: true,
        },
      ],
      statuses: [],
      key: 0,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.getProjectCallSummary();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    getFormatedTime(e) {
      return utils(e);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getProjectCallSummary() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + digimrProjectSumary
        );
        this.key += 1;
        const ps = response.data.project_summary;
        const uniqueProjects = [
          ...new Map(ps.map((item) => [item["project_id"], item])).values(),
        ];
        uniqueProjects.map((item) => {
          item.total_digimrs_updated = 0;
          item.total_calls_updated = 0;
          item.total_doctors_contacted_updated = 0;
          item.total_call_duration_updated = 0;
          item.avg_call_duration_updated = 0;
          item.total_successful_calls_updated = 0;
          item.total_doctors_contacted_successful_updated = 0;
          item.avg_successful_call_duration_updated = 0;
          item.total_successful_call_duration_updated = 0;
          ps.map((p) => {
            if (item.project_id == p.project_id) {
              item.total_digimrs_updated += 1;
              item.total_calls_updated += p.total_calls;
              item.total_doctors_contacted_updated += p.total_doctors_contacted;
              item.total_call_duration_updated += parseInt(
                p.total_call_duration
              );
              item.total_successful_calls_updated += parseInt(
                p.total_successful_calls
              );
              item.total_doctors_contacted_successful_updated += parseInt(
                p.total_doctors_contacted_in_successful_calls
              );
              item.total_successful_call_duration_updated +=
                p.total_successful_call_duration != null
                  ? parseInt(p.total_successful_call_duration)
                  : 0;
            }
          });
          item.total_call_duration_updated =
            item.total_call_duration_updated.toFixed(2);
          item.avg_call_duration_updated =
            item.total_call_duration_updated != 0
              ? (
                  item.total_call_duration_updated / item.total_calls_updated
                ).toFixed(2)
              : 0;
          item.avg_successful_call_duration_updated =
            item.total_successful_call_duration_updated != 0
              ? (
                  item.total_successful_call_duration_updated /
                  item.total_successful_calls_updated
                ).toFixed(2)
              : 0;
        });
        this.tableData = uniqueProjects;
      } catch (err) {
        console.log(err);
      }
    },
    getCellColumn(f) {
      return `cell(${f.replaceAll("_", " ")})`;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Today's Project Summary</h4>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              :items="tableData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :key="key"
            >
              <template v-slot:cell(total_call_duration_updated)="row">
                <span>{{ row.value }} sec</span>
              </template>
              <template v-slot:cell(avg_successful_call_duration_updated)="row">
                <span>{{ row.value }} sec</span>
              </template>
              <template v-slot:cell(avg_call_duration_updated)="row">
                <span>{{ row.value }} sec</span>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
