<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <TelecallerOverallAnalytics :telecallers_data="telecallers" />
    <projectLevelSummary />
    <Filters @filters="getFilters" :telecallers="telecallers" />
    <TelecallerAnalytics
      :telecallers_data="telecallers"
      :filters="data_filters"
    />
    <SplineChatHourly :filters="data_filters" />
  </Layout>
</template>

<script>
import TelecallerOverallAnalytics from "./telecaller-overall-analytics";
import TelecallerAnalytics from "./telecaller-analytics";
import Filters from "./filters";
import SplineChatHourly from "../charts/time-summary";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import projectLevelSummary from "./project-level-summary";

export default {
  name: "main-page",
  page: {
    title: "Telecaller Analytics",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "",
      items: [],
      telecallers: [],
      data_filters: [],
    };
  },
  methods: {
    async getTelecallers() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/telecallers",
          {
            params: {
              status: true,
              is_field_mr: false,
            },
          }
        );
        this.telecallers = response.data.telecallers;
      } catch (err) {
        console.log(err);
      }
    },
    getFilters(e) {
      this.data_filters = e;
    },
  },
  mounted() {
    this.getTelecallers();
  },
  components: {
    TelecallerOverallAnalytics,
    TelecallerAnalytics,
    Filters,
    Layout,
    PageHeader,
    SplineChatHourly,
    projectLevelSummary,
  },
};
</script>

<style scoped></style>
