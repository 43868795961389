function secondsToTime(e) {
  var h = Math.floor(e / 3600)
      .toString()
      .padStart(2, "0"),
    m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, "0");
  let result = "";
  if (h > 0) {
    result += h + "hr ";
  }
  if (m > 0) {
    result += m + "m ";
  }

  return result + s + "sec ";
}

export default secondsToTime;
